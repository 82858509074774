import { CheckboxGroup } from "../../../../common/components/CheckboxGroup.js";
import { useIntegrationStore } from "../../../../store/IntegrationStore.js";
import { mapActions, mapState } from 'pinia';

const GoogleAds = {
    name: "GoogleAdsTemplate",
    data: function () {
        return {
            campaigns: [],
            isLoading: false
        }
    },
    props: {
        campaignsOptions: Array,
        ads: [Object, Array],  // Array of objects if we need to connect more than one ads to the platform
        platform: Object,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        ...mapActions(useIntegrationStore, [
            'updateConnectionDetails'
        ]),
        init() {
            this.getCampaigns();
        },
        getCampaigns() {
            this.isLoading = true
            this.integrationsService.googleAds.getCampaigns(this.platform.type).then(response => {
                this.campaigns = this.constructCampaignsOptions(response);
                this.isLoading = false
                }
            )},
        constructCampaignsOptions(dataArray) {
            let adsCampaignIds
            // Preselect the connected campaign only if the user has selected a single ad
            if(!this.isAdsArray) {
                // Find the platform object with type 'google_ads'
                const googleAdsCampaigns = this.ads.platforms.find(platform => platform.type === this.platformNameMap.GOOGLE_ADS)?.data;
                // Convert this.ads.campaigns to a Set for efficient lookup
                if(googleAdsCampaigns) adsCampaignIds = new Set(googleAdsCampaigns.map(campaign => campaign.campaign_id));
            }

            // Sort the campaigns first by whether they are checked, then by name
            const sortedCampaigns = this.sortCampaignsByConnected(dataArray, adsCampaignIds)

            return sortedCampaigns.map(campaign => ({
                id: campaign.id,
                name: campaign.name,
                checked: this.shouldCampaignChecked(campaign, adsCampaignIds),
            }))
        },
        // Prioritize connected campaigns at the top
        sortCampaignsByConnected(dataArray, adsCampaignIds) {
            return dataArray.sort((a, b) => {
                if (this.isAdsArray || !adsCampaignIds?.has(a.id)) {
                    // Campaigns that are not checked or in bulk mode
                    return 0;
                } else if (!adsCampaignIds?.has(b.id)) {
                    // Campaigns that are checked but not in bulk mode
                    return -1;
                } else {
                    // Both campaigns are checked and in bulk mode
                    return a.name.localeCompare(b.name);
                }
            });
        },
        updateConnectionDetailsWithSelected(newSelectedArray){
            const selectedCampaigns = this.constructSelectedCampaigns(newSelectedArray)
            const isAllCampaignsValid = selectedCampaigns.length > 0 && selectedCampaigns.length == newSelectedArray.length;
            // These two different data structures are just to keep the backend API consistent
            if (this.isAdsArray) {
                this.updateConnectionDetails({ campaigns : selectedCampaigns }, isAllCampaignsValid)
            } else {
                this.updateConnectionDetails(selectedCampaigns, isAllCampaignsValid)
                }
        },
        constructSelectedCampaigns(selectedIds){
            // Filter campaigns objects based on selected ids 
            const selectedCampaigns = this.campaigns.filter(campaign => selectedIds.includes(campaign.id));
            // Validate each selected campaign and keep only id and name
            return selectedCampaigns.map(item => ({
                campaign_id: item.id,
                campaign_name: item.name
            }));
        },
        shouldCampaignChecked(campaign, adsCampaignIds){
            return !this.isAdsArray && adsCampaignIds?.has(String(campaign.id));
        },
    },
    mounted () {
        this.init()
    },
    components: {
        CheckboxGroup
    },
    computed: {
        ...mapState(useIntegrationStore, [
            'platformNameMap'
        ]),
        isAdsArray() {
            return Array.isArray(this.ads)
        }
    },
    template:
    `
    <div class="accordion" role="tablist">
        <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': !isLoading}">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-card no-body class="mb-1 border-0 rounded-0">
            <b-card-header header-tag="header" v-b-toggle.campaigns-accordion class="p-2 border-0 rounded-0 grass-background d-flex justify-content-between align-item-center" role="tab">
                <p class="m-0 text-left text-white font-weight-600">Campaigns</p>
                <font-awesome-icon class="arrow-down light-sage-color align-self-center" :icon="['fas', 'chevron-down']" />
            </b-card-header>

            <b-alert v-if="!campaigns.length" show variant="warning" class="mt-5 mx-4 align-self-center">
                No campaigns found. AdRetriever requires at least one Display campaign in Google Ads to connect an ad.
            </b-alert>

            <b-collapse id="campaigns-accordion" visible role="tabpanel">
                <b-card-body>
                    <CheckboxGroup :options="campaigns" groupLabel="Select Campaign(s)" valueField="id" @updateSelected="updateConnectionDetailsWithSelected" :ads="ads" :isAdsArray="isAdsArray" />
                </b-card-body>
            </b-collapse>
        </b-card>
    </div>
    `
}

export { GoogleAds };
