import { googleAdsStore } from "../../../../store/GoogleAdsFormSubmissionStore";
import { mapActions, mapState } from "pinia";

const FormSubmissionModal = {
  name: "FormSubmissionModal",
  data: function () {
    return {
      isFormOpened: false,
      isAnswerGiven: false,
      isAllGood: false,
    };
  },
  methods: {
    ...mapActions(googleAdsStore, [
      "updateFooterProps",
      "getAdsName",
      "updateAlertHandlers",
    ]),
    onYes() {
      try {
        this.integrationsService.googleAds
          .finalizeSubmission("google_ads")
          .then((response) => {
            this.isAnswerGiven = true;
            this.isAllGood = true;
          })
          .catch((error) => {
            this.handleSubmissionError(error);
          });
      } catch (error) {
        this.handleSubmissionError(error);
      }
    },
    onNo() {
      this.isAnswerGiven = true;
      this.isAllGood = false;
      window.open(
        window.adretriever.Basic.siteUrl + "/help/google-ads-tags",
        "_blank"
      );
      this.$emit("hideModal");
    },
    handleSubmissionError(error) {
      this.isAnswerGiven = true;
      this.isAllGood = false;
      this.updateAlertHandlers(false, true);
      console.error("Error finalizing form submission:", error);
    },
  },
  mounted() {
    this.updateFooterProps({
      isAnswerGiven: this.isAnswerGiven,
    });
  },
  watch: {
    isAnswerGiven(newValue) {
      this.updateFooterProps({
        isAnswerGiven: newValue,
      });
    },
  },
  computed: {
    ...mapState(googleAdsStore, ["integrationsService", "ads", "footerProps"]),
  },
  template: `
        <div class="modal-body">
            <div class="spruce-color mb-4">
                <span class="font-weight-bold">Manage your ad(s): </span>
                <span>{{ getAdsName() }}</span>
            </div>

            <div v-if="!footerProps.isAnswerGiven">
                <p>
                  <font-awesome-icon icon="circle-exclamation" size="xl" class="mr-1" style="color: orange;" /> 
                    Have you submitted the Google Ads' Third-Party Measurement Implementation Form Request with your ad tag file attached?
                </p>
                    <b-button @click="onYes" class="oval-border-button mb-2">
                      <div>
                        <p class="font-weight-bold mb-0">Yes, I have!</p>
                        <p class="font-size-md" mt-0>The Third-Party Measurement Implementation Request was submitted with my ad tag file.</p>
                      </div>
                          <font-awesome-icon icon="check" class="confirmation-button-icon" />
                    </b-button>
                    <b-button @click="onNo" class="oval-border-button">
                      <div>
                        <p class="font-weight-bold mb-0">No, I need assistance.</p>
                        <p class="font-size-md mb-2 mt-0">Access our support document for step-by-step guidance on the process.</p>
                      </div>
                          <font-awesome-icon icon="question" class="confirmation-button-icon" style="color: orange;" />
                    </b-button>
            </div>
            <div v-else-if="isAllGood">
              <p>
                <font-awesome-icon :icon="['fas', 'check-circle']" class="text-success mr-1" size="lg" />
                  Thank you for confirming! Google will review your request, which typically takes up to 24 hours. Once approved, the status in Adretriever will update to "Ready."
              </p>
            </div>
        </div>
    `,
};

export { FormSubmissionModal };
