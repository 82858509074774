/**
 * Flow:
 * 1. Get merchants list
 * 2. Once user selected a merchant
 *  2.2. Get country
 * 3. Save integration details when user clicks 'Connect'
 */
import { FilterableSelectDropdown } from "../../../common/components/FilterableSelectDropdown.js"
import { debounce } from "lodash";

const MerchantCenter = {
    name: 'MerchantCenter',
    data: function () {
        return {
            merchants: [],
            countries: [],
            selected: {
                merchant_id: null,
                country: null,
                storeCode: null
            },
            isInit: false, // Hide form if account list if not fetched
            searchQuery: "",
            isLoading: {
                merchants: false,
                countries: false,
            }
        }
    },
    props: {
        platform: Object,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        getMerchants() {
            this.integrationsService.merchantCenter.getMerchants(this.platform.type).then(response => {
                this.merchants =  this.constructMerchantsOptions(response)
                if(this.merchants.length === 1 && !this.selected.merchant_id) {
                    this.selected.merchant_id = this.merchants[0]
                }else{
                    this.isLoading.merchants = false
                }
                this.isInit = true
            })
        },
        constructMerchantsOptions(dataArray) {
            return dataArray.merchant_ids;
        },
        updateMerchantId() {
            this.integrationsService.update(this.platform.type, {
                data: {
                    merchant_id : this.selected.merchant_id
                }
            }).then(response => {
                this.getCountries()
            })
        },

        getCountries(isFreshStart = false) {
            this.isLoading.countries = true
            const params = {};
            if(this.searchQuery.length) params.search = this.searchQuery

            this.integrationsService.merchantCenter.getCountries(this.platform.type, params).then(response => {
                this.countries = response;
                this.isLoading.countries = false;
            })
        },
        constructDetails() {
            return {
                type: this.platform.type,
                data: { ...this.selected }
            }
        },
        // Functions for FilterableSelectDropdown
        onSearchQuery(searchQuery) {
            this.searchQuery = searchQuery
            if(searchQuery.length) {
                this.getCountries();
            }else{
                this.getCountries(true); // fresh start
            }
        },
        onSelectCountry(country) {
            this.selected.country = country
        }
    },
    mounted() {
        this.getMerchants()
    },
    computed: {
        componentProps() {
            return {
                placeholders: {
                    search: "Search country",
                    firstOption: "Select country",
                    noResult: "No country found"
                },
                data: this.countries,
                isLoading: this.isLoading.countries
            }
        },
        isAllSelected() {
            // Extract all values from the `selected` object
            const selectedValues = Object.values(this.selected);
            // Check if all extracted values are not null
            return selectedValues.every(value => value !== null);
        }
    },
    components: {
        FilterableSelectDropdown
    },
    watch: {
        'selected.merchant_id': function (newMerchantId, oldMerchantId) {
            // Check if accountId has actually changed
            if (newMerchantId !== oldMerchantId) {
                this.updateMerchantId()
            }
        },
        selected: {
            handler: debounce(function (newSelected, oldSelected) {
                this.$emit('updateIntegrationDetails', this.constructDetails());
            }, 300),
            deep: true // Watch nested properties
        },
        isAllSelected(newValue, oldValue) {
            this.$emit("updateFormReadystate", newValue)
        }
    },
    template:
        `
    <div class="border p-4 rounded">

        <div v-if='!isInit' class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': isInit}">
            <b-spinner label="Loading..."></b-spinner>
        </div>

        <b-form v-else="isInit">
            <div class="mb-2 position-relative">
                <label>Merchant</label>

                <div class="loading-overlay select-field-loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50 h-50" :class="{'hide': !isLoading.merchants}">
                    <b-spinner label="Loading..."></b-spinner>
                </div>

                <b-form-select v-model="selected.merchant_id" :options="merchants">
                    <template #first>
                        <b-form-select-option :value="null" disabled>Select merchant</b-form-select-option>
                    </template>
                </b-form-select>
            </div>

            <div class="mb-2 position-relative" v-if="selected.merchant_id">
                <label>Country</label>
                <div class="loading-overlay select-field-loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50 h-50" :class="{'hide': !isLoading.countries}">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <FilterableSelectDropdown v-bind="{...componentProps}" @onSearchQuery="onSearchQuery" @onSelectItem="onSelectCountry" />
            </div>

            <div class="mb-2" >
                <label>Store Code</label>
                <b-form-input v-model="selected.storeCode" placeholder="Enter store code"></b-form-input>
            </div>
        </b-form>
    </div>
    `
}

export { MerchantCenter };
