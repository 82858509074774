/**
 * Flow:
 * 1. Get account list
 * 2. Once user selected an account
 * 3. Save integration details (customer id) when user clicks 'Connect'
 */
import { FilterableSelectDropdown } from "../../../common/components/FilterableSelectDropdown.js"

const GoogleAds = {
    name: 'GoogleAds',
    data: function () {
        return {
            accounts: [],
            selected: { 
                customer_id: null,
                account_name: null
            },
            accountPageNumber: 1,
            isInit: false, // Hide form if account list if not fetched
            searchQuery: "",
            isLoading: {
                accounts: false,
            },
            totalAccountsPages: 0
        }
    },
    props: {
        platform: Object,
        websiteName: String,
        integrationsService: Object
    },
    methods: {
        getAccounts() {
            this.integrationsService.googleAds.getAccounts(this.platform.type).then(response => {
                this.accounts =  this.constructAccountsOptions(response)
                if(this.accounts.length === 1 && !this.selected.customer_id) {
                    this.selected.customer_id = this.accounts[0].id.value 
                }else{
                    this.isLoading.accounts = false
                }
                this.isInit = true
            })
        },
        constructAccountsOptions(dataArray) {
            return dataArray.map(item => ({
                value: item.id,
                text: item.descriptive_name
            }));
        },
        updateCustomerId() {
            this.integrationsService.update(this.platform.type, {
                settings: {
                    customer_id : this.selected.customer_id,
                    account_name: this.accounts.find(account => account.value === this.selected.customer_id).text,
                }
            })
        },
        constructDetails() {
            this.selected.account_name = this.accounts.find(account => account.value === this.selected.customer_id).text
            return {
                type: this.platform.type,
                settings: {
                  ...this.selected
                }
            }
        },
        // Functions for FilterableSelectDropdown
        filterAccounts(searchQuery) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            return this.accounts.filter(item => item.text.toLowerCase().includes(lowerCaseQuery));
        },
        onSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
            if(searchQuery.length) {
                this.accounts = this.filterAccounts(searchQuery);
            } else {
                this.getAccounts();
            }
        },
        onSelectAccount(accountId) {
            this.selected.customer_id = accountId
        }
    },
    mounted() {
        this.getAccounts();
    },
    computed: {
        componentProps() {
            return {
                placeholders: {
                    search: "Search accounts",
                    firstOption: "Select account",
                    noResult: "No account found"
                },
                data: this.accounts,
                isLoading: this.isLoading.accounts
            }
        },
        isAllSelected() {
            // Extract all values from the `selected` object where the key ends with '_id'
            const selectedIdValues = Object.entries(this.selected).reduce((acc, [key, value]) => {
                if (key.endsWith('_id')) {
                    acc.push(value);
                }
                return acc;
            }, []);

            // Check if all extracted values are not null
            return selectedIdValues.every(value => value !== null);
        }
    },
    components: {
        FilterableSelectDropdown
    },
    watch: {
        selected: {
            handler: function (newSelected, oldSelected) {
                this.$emit('updateIntegrationDetails', this.constructDetails());
            },
            deep: true // Watch nested properties
        },
        isAllSelected(newValue, oldValue) {
            this.$emit("updateFormReadystate", newValue)
        }
    },
    template:
        `
    <div class="border p-4 rounded">

        <div v-if='!isInit' class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': isInit}">
            <b-spinner label="Loading..."></b-spinner>
        </div>

        <b-form v-else="isInit">
            <div class="mb-2 position-relative">
                <label>Account</label>

                <div class="loading-overlay select-field-loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50 h-50" :class="{'hide': !isLoading.accounts}">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
                <FilterableSelectDropdown v-bind="{...componentProps}" @onSearchQuery="onSearchQuery" @onSelectItem="onSelectAccount" />
            </div>
        </b-form>
    </div>
    `
}

export { GoogleAds };
