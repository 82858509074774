import { googleAdsStore } from "../../../../store/GoogleAdsFormSubmissionStore";
import { mapActions, mapState } from "pinia";

const DownloadTags = {
  name: "DownloadTags",
  data: function () {
    return {
      pollingInterval: 3000,
      polling: null,
      isFileDownloaded: false,
    };
  },
  methods: {
    ...mapActions(googleAdsStore, [
      "updateFooterProps",
      "getAdsName",
      "updateAlertHandlers",
    ]),
    onDownloadFile() {
      clearInterval(this.polling);
      this.polling = null;
      this.integrationsService.googleAds
        .downloadTags("google_ads")
        .then((response) => {
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.download = "3PAS Media Plan with Tags.xlsx";
          link.click();
          URL.revokeObjectURL(url);

          if (response.status === 200) {
            this.isFileDownloaded = true;
          } else {
            console.error("Failed to download file: ", response);
          }
        })
        .catch((error) => {
          this.updateAlertHandlers(false, true);
          console.error("Error downloading file:", error);
        });
    },
  },
  mounted() {
    this.updateFooterProps({
      isFileDownloaded: this.isFileDownloaded,
      integrationsService: this.integrationsService,
    });
  },
  watch: {
    isFileDownloaded(newValue) {
      this.updateFooterProps({ isFileDownloaded: newValue });
    },
  },
  computed: {
    ...mapState(googleAdsStore, ["integrationsService", "ads"]),
  },
  template: `
        <div class="modal-body">
            <div class="spruce-color mb-4">
                <span class="font-weight-bold">Manage your ad(s): </span>
                <span>{{ getAdsName() }}</span>
            </div>

            <div>
                <p>
                  <font-awesome-icon :icon="['fas', 'check-circle']" class="text-success mr-1" size="lg" />
                    Success! Your tags have been generated. Please download your tag file to attach it in your Third-Party Measurement Implementation Request Form.</p>
                  <p class="font-size-sm">
                    <span class="font-weight-bold">Step 1</span> - Download your file
                  </p>
                    <div style="margin-top: 10px; margin-bottom: 15px;">
                      <b-button
                        :class="['marigold-button', 'font-weight-bold', { 'btn-disabled': isFileDownloaded }]" 
                        @click="onDownloadFile">
                        Download File 
                        <font-awesome-icon :icon="isFileDownloaded ? ['fas', 'check'] : ['fas', 'download']" class="ml-2" />
                      </b-button>
                        <font-awesome-icon 
                          icon="fas fa-info-circle" 
                          class="ml-1" 
                          style="color: orange;"
                          data-bs-toggle="tooltip" 
                          data-bs-placement="top" 
                          title="Google Ads requires third-party ads to connect using a tag file. AdRetriever has simplified the process by generating the file for you." />
                      </div>
                    <p class="font-size-sm">
                      <span class="font-weight-bold">Step 2</span> - Fill out the required fields in your request form and upload your tag file to complete the process
                    </p>
              </div>
          </div>
    `,
};

export { DownloadTags };
