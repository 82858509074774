import { IntegrationsService } from "../../../../common/api.service";
import { StatusAlert } from "../../../../common/components/StatusAlert";
import { GenerateTags } from "./GenerateTags";
import { DownloadTags } from "./DownloadTags";
import { FormSubmissionModal } from "./FormSubmission";
import { googleAdsStore } from "../../../../store/GoogleAdsFormSubmissionStore";
import { mapActions, mapState } from "pinia";

let SpreadsheetModal = {
  props: {
    ads: [Array, Object],
    websiteName: String,
    modalStep: String,
    staticUrl: String,
    isShowSpreadsheetModal: Boolean,
  },
  data() {
    return {
      currentStep: this.modalStep || "generateTags",
      templateByStep: {
        generateTags: GenerateTags,
        downloadTags: DownloadTags,
        confirmation: FormSubmissionModal,
      },
    };
  },
  watch: {
    ads: {
      immediate: true,
      handler(newAds) {
        this.saveAdsToStore(newAds);
      },
    },
    modalStep: {
      immediate: true,
      handler(newStep) {
        this.currentStep = newStep;
      },
    },
  },
  methods: {
    ...mapActions(googleAdsStore, [
      "updateFooterProps",
      "saveAdsToStore",
      "setIntegrationService",
      "updateAlertHandlers",
    ]),
    closeModal(bvModalEvent = null, isRefresh = true, resetStep = true) {
      if (resetStep) {
        this.currentStep = null;
      }
      if (bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault();
      }
      this.$emit("hideModal", isRefresh);
      this.updateAlertHandlers(true, false);
    },
    proceedToNextStep(nextStep) {
      this.currentStep = nextStep;
      this.updateAlertHandlers(true, false);
    },
    onProceedToForm(bvModalEvent) {
      window.open(
        "https://support.google.com/google-ads/answer/6161966?hl=en",
        "_blank"
      );
      this.proceedToNextStep("confirmation");
    },
    onBack() {
      this.proceedToNextStep("downloadTags");
    },
  },
  mounted() {
    this.setIntegrationService(new IntegrationsService(this.websiteName));
  },
  computed: {
    ...mapState(googleAdsStore, [
      "integrationsService",
      "footerProps",
      "isSuccess",
      "isShowAlert",
    ]),
  },
  components: {
    StatusAlert,
    GenerateTags,
    DownloadTags,
    FormSubmissionModal,
  },
  template: `
    <b-modal
      v-model="isShowSpreadsheetModal"
      :id="currentStep"
      :ref="currentStep"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
      centered
      @cancel="(e) => closeModal(e, false)"
      @close="(e) => closeModal(e, false)">

        <template #modal-title>
          <div class="modal-title">
            <img class="platform-icon mr-2" :src="staticUrl + 'img/google_ads-logo.png'" />
            Manage Google Ads Connections
          </div>
        </template>

        <StatusAlert :isSuccess="isSuccess" :isShowAlert="isShowAlert" />

        <component 
          :is="templateByStep[currentStep]" 
          :staticUrl="staticUrl" 
          :modalStep="currentStep" 
          @hideModal="closeModal"
          @proceedToNextStep="proceedToNextStep">
        </component>

        <template #modal-footer>
          <div v-if="currentStep != 'confirmation'">
            <b-button class="btn-spruce-outline m-1" @click="(e) => closeModal(e, true)">
              Cancel
            </b-button>
            <b-button class="btn-spruce m-1" :disabled="!footerProps.isFileDownloaded" @click="onProceedToForm">
              Proceed to Form
            </b-button>
          </div>
          <div v-else>
            <b-button class="btn-spruce-outline m-1" @click="onBack">
              Back
            </b-button>
            <b-button v-if="footerProps.isAnswerGiven" class="btn-spruce m-1" @click="closeModal">
              Close
            </b-button>
          </div>
        </template>
    </b-modal>
`,
};

export { SpreadsheetModal };
