import { VueEllipseProgress } from "vue-ellipse-progress";

const RenderingStatus = "rendering";
const ConnectingStatus = "connecting";
const DisconnectingStatus = "disconnecting";
const WarningStatus = "warning";
const ErrorStatus = "error";
const ReadyStatus = "ready";

const ConnectionDetail = "connection-details";
const DisconnectionDetail = "disconnection-details";
const ConnectionError = "connection-errors";
const GoogleAdWaitingFormSubmission = "google-ad-form-submission";

const get_display_name = (name) => {
  const name_mapping = {
    gcm: "CM360",
    meta: "Meta",
    merchant_center: "Merchant Center",
    google_ads: "Google Ads",
  };
  return name_mapping[name];
};

const createPill = (
  status,
  tooltipText,
  origin = null,
  shouldUnderline = false
) => {
  const pillDetails = {
    [RenderingStatus]: {
      pillVariant: "secondary",
      pillText: "In Progress",
    },
    [ConnectingStatus]: {
      pillVariant: null,
      pillText: "In Progress",
    },
    [DisconnectingStatus]: {
      pillVariant: null,
      pillText: "In Progress",
    },
    [WarningStatus]: {
      pillVariant: "warning",
      pillText: "Warning",
    },
    [ErrorStatus]: {
      pillVariant: "danger",
      pillText: "Error",
    },
    [ReadyStatus]: {
      pillVariant: "success",
      pillText: "Ready",
    },
  };

  if (origin !== null && tooltipText !== null) {
    tooltipText = `Origin: ${origin}, Detail: ${tooltipText}`;
  }

  return {
    status: status,
    tooltipText: tooltipText,
    pillVariant: pillDetails[status].pillVariant,
    pillText: pillDetails[status].pillText,
    shouldUnderline: shouldUnderline,
  };
};

let AdListStatus = {
  props: {
    ad: {
      type: Object,
      validator(val) {
        return (
          val.hasOwnProperty("itemset") &&
          val.hasOwnProperty("progress") &&
          val.hasOwnProperty("platforms") &&
          val.itemset !== undefined &&
          typeof val.progress === "number" &&
          // Maybe Array.isArray(val.platforms)?
          typeof val.platforms === "object"
        );
      },
    },
  },
  data: function () {
    return {
      current: {
        status: null,
        tooltipText: null,
        pillVariant: null,
        pillText: null,
        shouldUnderline: false,
      },
      isCheckStatusDone: false,
    };
  },
  computed: {
    videoProgress: function () {
      // return null if there is error so we dont show progress bar
      return this.ad.progress >= 0 ? this.ad.progress : null;
    },
    /*
    Use this function to report everything related to platform
    */
    platformStatus() {
      let connectionDetails = [];
      let disconnectionDetails = [];
      let connectionErrorDetails = [];
      let googleAdFormSubmission = false;
      this.ad.platforms.forEach((platform) => {
        platform.data.forEach((platformData) => {
          if (this.isWaitingForFormSubmission(platform, platformData)) {
            googleAdFormSubmission = true;
          }
          if (platformData.status === "connecting") {
            connectionDetails.push({ type: platform.type, data: platformData });
          } else if (platformData.status === "disconnecting") {
            disconnectionDetails.push({
              type: platform.type,
              data: platformData,
            });
          } else if (platformData.status === "error") {
            connectionErrorDetails.push({
              type: platform.type,
              data: platformData,
            });
          }
        });
      });
      return {
        [ConnectionDetail]: connectionDetails,
        [DisconnectionDetail]: disconnectionDetails,
        [ConnectionError]: connectionErrorDetails,
        [GoogleAdWaitingFormSubmission]: googleAdFormSubmission,
      };
    },
    isItemsetDeleted() {
      return this.ad.itemset.deleted === true;
    },
    isItemsetEmpty() {
      return this.ad.itemset.has_items === false;
    },
  },
  methods: {
    inRender() {
      return this.ad.progress >= 0 && this.ad.progress < 100;
    },
    hasRenderError() {
      return this.ad.progress < 0;
    },
    renderErrorMsg() {
      if (this.ad.progress === -1) {
        return "Video rendering failed";
      } else if (this.ad.progress === -2) {
        return "One or more videos failed to render";
      } else {
        return "Unknown error";
      }
    },
    hasWarning() {
      return (
        this.isItemsetDeleted ||
        this.isItemsetEmpty ||
        this.platformStatus[GoogleAdWaitingFormSubmission]
      );
    },
    warningMsg() {
      if (this.platformStatus[GoogleAdWaitingFormSubmission]) {
        return "Please complete form submission";
      } else if (this.isItemsetDeleted) {
        return "This ad is using a deleted set";
      } else if (this.isItemsetEmpty) {
        return "This ad has no items—it’s serving the default ad";
      } else {
        return "Unknown error";
      }
    },
    connectingMsg() {
      const platformType = this.platformStatus[ConnectionDetail][0].type;

      if (platformType === "google_ads") {
        const googleAdsPlatform = this.ad.platforms.find(
          (platform) => platform.type === "google_ads"
        );

        const isWaitingForAdsAdded =
          googleAdsPlatform?.data.some(
            (adConnection) => adConnection.state === "pending_ad_review"
          ) ?? false;

        if (isWaitingForAdsAdded) {
          return "Pending Google review. Thank you for your patience.";
        }
      }
      return `Ad is connecting to ${get_display_name(platformType)}`;
    },
    getWarningOrigin() {
      // Order matters here
      if (this.platformStatus[GoogleAdWaitingFormSubmission]) {
        return "Google Ads";
      } else if (this.isItemsetDeleted || this.isItemsetEmpty) {
        return "item set";
      }
    },
    hasPlatformConnection() {
      return this.platformStatus[ConnectionDetail].length !== 0;
    },
    hasPlatformDisconnection() {
      return this.platformStatus[DisconnectionDetail].length !== 0;
    },
    hasPlatformConnectionError() {
      return this.platformStatus[ConnectionError].length !== 0;
    },
    checkStatus() {
      if (this.inRender()) {
        this.current = createPill(
          RenderingStatus,
          `${this.ad.progress}% Rendered`
        );
      } else if (this.hasRenderError()) {
        this.current = createPill(ErrorStatus, this.renderErrorMsg());
      } else {
        if (this.hasPlatformConnectionError()) {
          this.current = createPill(
            ErrorStatus,
            `${this.platformStatus[ConnectionError][0].data.error_message}`,
            `${get_display_name(this.platformStatus[ConnectionError][0].type)}`
          );
        } else if (this.hasWarning()) {
          const msg = this.warningMsg();
          const shouldUnderline = msg === "Please complete form submission";
          const warningOrigin = this.getWarningOrigin();
          this.current = createPill(
            WarningStatus,
            msg,
            warningOrigin,
            shouldUnderline
          );
        } else if (this.hasPlatformConnection()) {
          this.current = createPill(ConnectingStatus, this.connectingMsg());
        } else if (this.hasPlatformDisconnection()) {
          this.current = createPill(
            DisconnectingStatus,
            `Ad is disconnecting from ${get_display_name(
              this.platformStatus[DisconnectionDetail][0].type
            )}`
          );
        } else {
          this.current = createPill(ReadyStatus, "Ready");
        }
      }

      this.$nextTick(() => {
        this.isCheckStatusDone = true;
      });
    },
    isWaitingForFormSubmission(platform, platformData) {
      return (
        platform.type === "google_ads" &&
        platformData.state === "ready_for_spreadsheet"
      );
    },
    openDownloadGoogleSheetModal() {
      if (this.platformStatus[GoogleAdWaitingFormSubmission]) {
        this.$emit("openSpreadsheetModal", "downloadTags");
      }
    },
    getStatusDivId(divName) {
      return `${divName} - ${this.ad.id}`;
    },
  },
  watch: {
    ad: {
      immediate: true,
      deep: true,
      handler() {
        this.checkStatus();
      },
    },
  },
  components: {
    "vue-ellipse-progress": VueEllipseProgress,
  },
  template: `
    <div :key="ad.id">
        <b-badge :id="getStatusDivId('badge')" class="ad-list-status-pill p-2 d-flex flex-row align-items-center" pill  :variant="current.pillVariant">
            <vue-ellipse-progress
            v-if="current.status === 'rendering'"
            :progress="videoProgress"
            color="#6c757d"
            :size="16"
            :thickness="2"
            :legend="false"
            class="mr-1"
            />
            <font-awesome-icon class="mr-1" v-else-if="current.status === 'connecting' || current.status === 'disconnecting'" :icon="['fas', 'spinner']" spin-pulse />
            <font-awesome-icon class="mr-1" v-else-if="current.status === 'warning'" :icon="['fas', 'triangle-exclamation']"  />
            <font-awesome-icon class="mr-1" v-else-if="current.status === 'error'" :icon="['fas', 'triangle-exclamation']"  />
            <font-awesome-icon class="mr-1" v-else :icon="['fas', 'circle-check']" />
            <span class="font-weight-normal">{{ current.pillText }}</span>
        </b-badge>

        <b-popover
            v-if="isCheckStatusDone"
            :key="current.tooltipText"
            :variant="current.pillVariant"
            :target="getStatusDivId('badge')"
            triggers="hover focus"
            placement="top"
            :id="getStatusDivId('popover')">
            <template #title>
                <p
                  class="mb-0"
                  @click="openDownloadGoogleSheetModal"
                  :style="{
                    textDecoration: current.shouldUnderline ? 'underline' : 'none' ,
                    cursor: current.shouldUnderline ? 'pointer' : 'default'
                  }"
                >
                    {{  current.tooltipText }}
                </p>
            </template>
        </b-popover>
    </div>
    `,
};

export { AdListStatus };
