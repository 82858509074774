import { googleAdsStore } from "../../../../store/GoogleAdsFormSubmissionStore";
import { mapActions, mapState } from "pinia";

const GenerateTags = {
  name: "GenerateTags",
  data: function () {
    return {
      pollingInterval: 3000,
      polling: null,
      googleAdsAdsStates: [],
      isGetStateReady: false,
    };
  },
  methods: {
    ...mapActions(googleAdsStore, [
      "updateFooterProps",
      "getAdsName",
      "updateAlertHandlers",
    ]),
    getAdsIds() {
      if (!this.ads) return;
      if (this.ads?.id) return this.ads.id;
      // If ads is an array
      return this.ads.map((obj) => obj.id).join(",");
    },
    setupStatePolling() {
      if (!this.isReadyForDownload) {
        if (this.polling) return;
        this.polling = setInterval(() => {
          this.getStates();
        }, this.pollingInterval);
      } else {
        if (this.polling) {
          clearInterval(this.polling);
          this.polling = null;
        }
      }
    },
    getStates() {
      if (!this.ads || this.ads.length == 0) {
        return;
      }
      this.integrationsService.googleAds
        .getStates("google_ads", this.getAdsIds())
        .then((response) => {
          this.isGetStateReady = true;
          this.googleAdsAdsStates = response;
          this.setupStatePolling();
        })
        .catch((error) => {
          this.updateAlertHandlers(false, true);
          console.error("Error getting states:", error);
        });
    },
  },
  mounted() {
    this.updateFooterProps({
      integrationsService: this.integrationsService,
    });
    this.getStates();
  },
  destroyed() {
    clearInterval(this.polling);
  },
  watch: {
    isReadyForDownload: {
      handler(newVal) {
        if (newVal) {
          this.$emit("proceedToNextStep", "downloadTags");
        }
      },
    },
  },
  computed: {
    ...mapState(googleAdsStore, [
      "integrationsService",
      "ads",
      "isSuccess",
      "isShowAlert",
    ]),
    isReadyForDownload() {
      if (this.googleAdsAdsStates.length === 0) {
        return false;
      } else {
        const shouldShowAlert = !this.isSuccess || this.isShowAlert;
        return !(
          this.googleAdsAdsStates.some(
            (state) => state === "pending_ad_group_creation"
          ) || shouldShowAlert
        );
      }
    },
  },
  template: `
        <div class="modal-body generate-tags">
            <div class="spruce-color mb-4">
                <span class="font-weight-bold">Manage your ad(s): </span>
                <span>{{ getAdsName() }}</span>
            </div>

            <div v-if="!isGetStateReady">
              <div class="modal-height-maintainer">
                  <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50">
                      <b-spinner label="Loading..."></b-spinner>
                  </div>
              </div>
            </div>

            <div v-else>
                <p>Generating your tags. This may take a moment...</p>
                <p class="font-size-sm">Please stay on this screen to ensure everything is processed smoothly.</p>
                <div style="display: flex; justify-content: center; align-items: center; min-height: 150px; width: 100%;">
                    <div class="loading" style="width: 100px; height: 100px;"></div>
                </div>
            </div>
        </div>
    `,
};

export { GenerateTags };
