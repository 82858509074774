import { defineStore } from "pinia";

export const googleAdsStore = defineStore("googleAds", {
  state: () => ({
    footerProps: {
      isFileDownloaded: false,
      isAnswerGiven: false,
    },
    integrationsService: null,
    ads: [],
    isSuccess: true,
    isShowAlert: false,
  }),
  actions: {
    setIntegrationService(service) {
      this.integrationsService = service;
    },
    saveAdsToStore(newAds) {
      this.ads = newAds;
    },
    updateFooterProps(newFooterProps) {
      this.footerProps = { ...this.footerProps, ...newFooterProps };
    },
    updateAlertHandlers(isSuccess, isShowAlert) {
      this.isSuccess = isSuccess;
      this.isShowAlert = isShowAlert;
    },
    getAdsName() {
      if (!this.ads) return;
      if (this.ads?.name) return this.ads.name;
      // If ads is an array
      return this.ads.map((obj) => obj.name).join(", ");
    },
  },
});
