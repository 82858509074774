// noinspection ES6PreferShortImport
import {BaseList} from "../BaseList.js";
import ApiService, { BulkAdsService, AdsService } from "../common/api.service.js";
import {AdListStatus} from "../AdListStatus.js";
import { ConnectionModal } from "../ads/components/ConnectionModal.js"
import { ConfirmationModal } from "../common/components/ConfirmationModal.js";
import { ConnectionToggle } from "../ads/components/ConnectionToggle.js";
import { convertDatetimeToRelativeTime } from "../utils/date_utils.js";
import { StatusAlert } from "../common/components/StatusAlert.js";
import { SpreadsheetModal } from "../ads/components/ConnectionModalTemplates/GoogleAds/SpreadsheetModal.js";

const AdsTable = {
    components: {
        BaseList,
        AdListStatus,
        ConnectionModal,
        ConfirmationModal,
        ConnectionToggle,
        StatusAlert,
        SpreadsheetModal
    },
    props: {
        resource: String,
        website: String,
        platformEnabled: Boolean,
        pollingInterval: Number,
        approved_default_ad: Boolean,
        showDefaultAlert: Boolean,
        createDefaultAds: String,
        userId: String,
        downloadTagsUrl: String,
        staticUrl: String
    },
    data: function () {
        return ({
            fields: [
                {
                    key: 'selected',
                    tdClass: 'pt-2'
                },
                {
                    key: 'preview', // Ad Preview
                    label: "",
                    thClass: 'p-0',
                    tdClass: 'px-0'
                },
                {
                    key: 'type',
                    label: "Format",
                    sortable: true,
                },
                {
                    key: 'name',
                    label: "Name",
                    sortable: true,
                    tdClass: 'd-flex justify-content-between'
                },
                {
                    key: 'status',
                    label: "Status",
                },
                {
                    key: 'itemset',
                    label: "Set",
                    sortable: true,
                    formatter: value => { return value.name },
                },

                {
                    key: 'theme_config',
                    label: "Theme",
                    formatter: value => {
                        return (value.name) ? value.name : ''
                    }
                },
                {
                    key: 'platforms',
                    label: "Connections",
                    sortable: false,
                    thClass: 'col-2',
                },
                {
                    key: 'created_at',
                    label: "Created at",
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                    tdClass: "pt-2"
                },
            ],
            sortBy: '',
            sortDesc: '',
            adUrl: '/{website}/ad/{id}/edit',
            getTagsUrl: '/{website}/ad/{id}/tags',
            getVideosUrl: '/{website}/ad/{id}/videos',
            expand: 'itemset,theme_config,platforms',
            only: 'id,name,website_name,theme_config__name,theme_config__theme_version_id,theme_config__url_params,itemset__deleted,itemset_id,itemset__has_items,itemset__name,type,progress,created_at,platforms',
            share_url: "",
            ad_name_modal:"",
            selectedAds: [],
            selectedAd: null, // For row action delete single ad
            selectedAction: null,
            searchQuery: "", // Search ads by name
            isShowConnectionModal: false,
            isShowDeleteConfirmationModal: false,
            connectionModalType: null,
            connectionAd: null,
            connectionModalPlatform: null,
            // Platform list
            platforms: [],
            enabledPlatforms: [],
            // Error handling for ad deletion
            isSuccessDelete: null,
            isShowDeleteAlert: false,
            deletionErrorMsg: "",
            // Special handling for Google Ads Modals
            isShowSpreadsheetModal: false,
            spreadsheetModalCurrentStep: null,
        })
    },
    watch: {
        selectedAction(newValue) {
            switch (newValue) {
                case "delete":
                    this.isShowDeleteConfirmationModal = true
                    break;
                case "connect":
                    this.showConnectionModal(this.selectedAds, "connect")
                    break
                case "disconnect":
                    this.showConnectionModal(this.selectedAds, 'disconnect')
                    break
                case "downloadTags":
                    this.onDownloadTags()
                    break
                default:
                    break;
            }
        }
    },
    computed: {
        actionsAvailable: function() {
            return this.selectedAds.length > 0;
        },
        allSelected: function () {
            return this.selectedAds.length > 0 && this.selectedAds.length === this.$refs.adsTable.items.length;
        },
        connectionModalProps () {
            return {
                websiteName: this.website,
                ads: this.connectionAd,
                platform: this.connectionModalPlatform,
                type: this.connectionModalType,
                isShow: this.isShowConnectionModal,
                platforms: this.platforms,
                staticUrl: this.staticUrl
            }
        },
        googleAdsSpreadsheetModalProps () {
            return {
                ads: this.connectionAd,
                websiteName: this.website,
                isShowSpreadsheetModal: this.isShowSpreadsheetModal,
                staticUrl: this.staticUrl,
                modalStep: this.spreadsheetModalCurrentStep,
            }
        },
    },
    mounted () {
        this.getPlatforms();
    },
    methods: {
        shareURL(ad, isOpenPreview = false) {
            this.ad_name_modal = ad.name
          ApiService.query('copy/ad/url', {
              'ad_id': ad.id,
              'website': ad.website_name,
              'type': ad.type,
          }).then(response => {
              this.share_url = window.location.origin + "/preview/" + response['token']
              if(isOpenPreview) window.open(this.share_url, '_blank')
          })
        },
          copyShareURL() {
              let input = document.querySelector('input');
              input.select()
              navigator.clipboard
                  .writeText(this.share_url)
                  .then(() => {console.log("successfully copied");})
                  .catch(() => {console.log("something went wrong");});
          },

        buildAdURL(buildURL, item, type) {
            if(type == 'video') {
                return buildURL({id: item.id, website: this.website}, this.adUrl + "?type=video")
            } else {
                return buildURL({id: item.id, website: this.website}, this.adUrl)
            }
        },
        buildIFrameUrl(buildURL, item, type) {
             if (type === 'video') {
                return buildURL({website: this.website, id: item.id}, "/{website}/ad/{id}/preview")
            } else if (item.theme_config?.theme_version_id !== undefined) {
                 if(item.itemset?.has_items){
                     return `${window.location.origin}/${this.website}/template/preset/preview?` +
                     `${item.theme_config.url_params}&itemset_id=${item.itemset_id}&theme_id=${item.theme_config.theme_version_id}`;
                 }
                 else{
                     return null;
                 }
            }
        },
        handleIframeLoad(event){
          const iframe = event.target;
          const url = iframe.src;
          if(!url){
              iframe.contentWindow.document.body.innerHTML = '<div style="font-size: 25px; color: red; text-align: center; padding: 50px 0; background-color: #f9f7ec">The itemset associated to this advertisement is empty. Default advertisement is currently served.</div>';
          }
        },
        buildGetTagsUrl(buildURL, item) {
            return buildURL({
                id: item.id,
                website: this.website
            }, this.getTagsUrl)
        },
        buildGetVideosUrl(buildURL, item) {
            return buildURL({
                id: item.id,
                website: this.website
            }, this.getVideosUrl)
        },
        isAssigned(item) {
            // Find the platform object with type 'gcm'
            const gcmPlatform = item.platforms.find(platform => platform.type === 'gcm');
            return gcmPlatform && gcmPlatform?.campaigns.length > 0;
        },
        convertDatetime(date_time){
            return convertDatetimeToRelativeTime(date_time)
        },
        hasMultipleCampaigns(item) {
            return item.campaigns.length > 1
        },
        onClickDeleteAd(item){
            this.selectedAd = item
            this.isShowDeleteConfirmationModal = true
        },
        onRowSelected(ads) {
            this.selectedAds = ads;
        },
        onRowChanged(index, isSelected) {
            isSelected ? this.$refs.adsTable.selectRow(index) : this.$refs.adsTable.unselectRow(index);
        },
        toggleAllSelection() {
            this.allSelected? this.$refs.adsTable.clearSelected() : this.$refs.adsTable.selectAllRows();
        },
        showConnectionModal(ad, type, platformType = ''){
            this.connectionAd = ad // TODO for multiple can direct = selectedAds
            this.isShowConnectionModal = true
            this.connectionModalType = type
            this.connectionModalPlatform = platformType || ""
        },
        hideConnectionModal(isRefresh = true){
            this.isShowConnectionModal = false
            this.connectionModalType = null
            if(isRefresh) this.$refs.adsTable.$emit("refresh");
            this.resetBulkActionsMenu()
        },
        updateAdCount(adCount){
            this.$emit('updateAdCount', adCount)
        },
        onDownloadTags(){
            window.open(this.downloadTagsUrl, '_blank');
            this.resetBulkActionsMenu()
        },
        getPlatforms() {
            ApiService.doRequest('get', `../api/${this.website}/platforms/statuses/`, {}).then(response => {
                this.platforms = response.platforms
                this.enabledPlatforms = response.platforms.filter(platform => platform.status === 'enabled');
            })
        },
        duplicateAd(ad) {
            AdsService.init(this.website).duplicateAd(ad.type, {ads: [ad.id]}).then(response => {
                this.$refs.adsTable.$emit("refresh");
            })
        },
        onConfirmDelete(bvModalEvent){
            if(this.selectedAd) {
                this.deleteAd(bvModalEvent)
            }else{
                this.bulkDeleteAds(bvModalEvent)
            }
        },
        // Delete single ad
        deleteAd(bvModalEvent) {
            bvModalEvent.preventDefault()
            ApiService.delete(`${this.website}/displayads`, this.selectedAd.type + '/' + this.selectedAd.id).then((response) => {
                this.selectedAd = null
                this.isShowDeleteConfirmationModal = false
                this.$refs.adsTable.$emit("refresh");
            }).catch(error => {
                this.isSuccessDelete = false
                this.isShowDeleteAlert = true;
                this.deletionErrorMsg = error.response.data.detail;
            });
        },
        bulkDeleteAds(bvModalEvent) {
            bvModalEvent.preventDefault()
            const data = { ad_ids: this.selectedAds.map(ad => ad.id) };
            BulkAdsService.init(this.website).post(data, 'delete')
                .then((response) => {
                    this.$refs.adsTable.$emit("refresh");
                    this.isShowDeleteConfirmationModal = false
                    this.resetBulkActionsMenu()
                }).catch(error => {
                    this.isSuccessDelete = false
                    this.isShowDeleteAlert = true;
                    this.deletionErrorMsg = error.response.data.detail;
                });
        },
        resetConfirmDeleteModal(){
            this.isSuccessDelete = null
            this.isShowDeleteAlert = false;
            this.deletionErrorMsg = ''
        },
        onCancelDelete(){
            if(this.selectedAd) this.selectedAd = null
            this.isShowDeleteConfirmationModal = false
            this.resetBulkActionsMenu()
            this.resetConfirmDeleteModal()
        },
        getPlatformTypes(item) {
            return item.platforms.filter(platform => {
                // Include Google Ads only if the form was submitted and it's pending review
                if (platform.type === "google_ads") {
                    return platform.data[0].state === "pending_ad_review";
                }
                // Include all the other platforms without any checks
                return true;
            }).map(platform => platform.type);
        },
        getIntegrationsUrl() {
            return `${window.location.origin}/${this.website}/settings#/integrations`
        },
        getSelectedAdsName() {
            if(this.selectedAd) return this.selectedAd.name
            return this.selectedAds.map(ad => ad.name).join(', ');
        },
        onDownloadVideo(buildURL, item) {
            const url = this.buildGetVideosUrl(buildURL, item)
            window.open(url, '_blank')
        },
        isAdRenderError(adProgress) {
            return adProgress < 0
        },
        resetBulkActionsMenu(){
            this.selectedAction = "null"
        },
        openGoogleAdsSpreadsheetModal(currentStep) {
            this.isShowSpreadsheetModal = true
            this.spreadsheetModalCurrentStep = currentStep
        },
        hideGoogleAdsSpreadsheetModal(isRefresh = true) {
            this.isShowSpreadsheetModal = false
            this.spreadsheetModalCurrentStep = null
            if(isRefresh) this.$refs.adsTable.$emit("refresh");
        },
    },
    template: `
    <BaseList
    :fields="fields"
    :expand="expand"
    :only="only"
    :sortBy="sortBy"
    :sortDesc="sortDesc"
    :resource="resource"
    :pollingInterval="pollingInterval"
    :userId="userId"
    :searchQuery="searchQuery"
    @updateAdCount="updateAdCount">
      <template v-slot:table="cdata">
        <div class="row no-gutters justify-content-between">
            <b-alert v-if="!enabledPlatforms.length"  show variant="success">
                <font-awesome-icon :icon="['fas', 'circle-info']" class="mr-3" size="lg" />
                <span>
                    Ready to put your ads into market? <a class="underline" :href="getIntegrationsUrl()" target="_blank"><u>Enable an integration</u></a>
                </span>
            </b-alert>
            <a v-if="approved_default_ad" :href="createDefaultAds" class="btn forrest-background h-100 text-white p-2 btn-sm float-left mb-3 font-weight-bold">Edit Default Ad</a>
        </div>
        <div class="row no-gutters">
            <b-alert v-if="!approved_default_ad && showDefaultAlert"  show variant="warning"> <font-awesome-icon style="color:orange" :icon="['fas', 'triangle-exclamation']" class="mr-3" size="lg"/> Paws! You have not previewed your default ad yet. Please preview your default ad. <a class="ml-4 btn btn-primary" :href="createDefaultAds">Preview Default Ad</a></b-alert>
        </div>
        <div class="row no-gutters d-flex mb-1 justify-content-between">
            <div class="selected-ads-container d-flex flex-row align-items-baseline">
                <p v-if="selectedAds.length" class="mb-0 spruce-color mr-3">
                    {{ selectedAds.length }} Item<span v-if="selectedAds.length > 1">s</span> selected
                </p>
                <p v-else class="mb-0 spruce-color mr-3">Select Items</p>
                <b-form-select v-model="selectedAction" :disabled="!actionsAvailable" class="spruce-color w-auto bg-transparent">
                    <template #first>
                        <b-form-select-option value="null" disabled>Actions</b-form-select-option>
                    </template>
                    <!-- <b-form-select-option value="downloadTags">
                        Get tags
                    </b-form-select-option> -->
                    <b-form-select-option value="connect">
                        Connections
                    </b-form-select-option>
                    <b-form-select-option value="disconnect">
                        Disconnect
                    </b-form-select-option>
                    <b-form-select-option value="delete">Delete</b-form-select-option>
                </b-form-select>
            </div>
            <b-input-group class="search-input-group col-3 justify-content-end">
                <b-input-group-prepend>
                    <span class="input-group-text border-0 bg-transparent"><font-awesome-icon class="spruce-color" :icon="['fas', 'magnifying-glass']" /></span>
                </b-input-group-prepend>
                <b-form-input v-model="searchQuery" class="search-input border-0 col-8 bg-transparent" size="md" placeholder="Search by name..." ref="searchInputField"></b-form-input>
            </b-input-group>
        </div>
        <b-table ref="adsTable" class="default-theme-border-radius table-rounded" thead-class="default-theme-border-radius border-0 forrest-background text-white" tbody-tr-class="forrest-color" table-variant="light"
            id="ads-list-table"
            :no-local-sorting="true"
            :no-sort-reset="true"
            :sort-by.sync="cdata.sortBy"
            :sort-desc.sync="cdata.sortDesc"
            :busy="cdata.busy"
            @sort-changed="cdata.sortChanged"
            :current-page="cdata.currentPage"
            :items="cdata.items"
            @refresh="cdata.refresh"
            :fields="fields"
            hover outlined no-border-collapse
            selectable no-select-on-click
            :select-mode="'multi'"
            @row-selected="onRowSelected"
            >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
        <template #head(selected)="data">
            <b-form-checkbox :checked="allSelected" @change="toggleAllSelection" class="prevent-select"/>
        </template>

        <template #cell(preview)="data">
            <b-dropdown variant="link" toggle-class="align-self-start pt-0 pl-0 pr-4 primary" no-caret>
                <template #button-content>
                    <font-awesome-icon  v-if="data.item.itemset?.has_items" :icon="['fas', 'eye']" />
                    <font-awesome-icon v-else :icon="['fas', 'eye-slash']" />
                </template>
                <div class="p-3">
                    <div style="position: absolute; top: 0; left: 0; width:95%; height:82%;z-index:2"></div>
                    <iframe class='m2-2 border'  id='ad_iframe' width="300" height="250" :src="buildIFrameUrl(cdata.buildURL,data.item,data.item.type)" @load="handleIframeLoad" scrolling="no" frameborder="0"/>
                </div>
                <b-button v-if="data.item.type == 'video'" variant="outline" class="w-100 px-4 spruce-color d-flex justify-content-between align-items-baseline" @click="shareURL(data.item, true)">
                    <p class="m-0">View Full Preview</p>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </b-button>
                <b-button v-else variant="outline" class="w-100 px-4 spruce-color d-flex justify-content-between align-items-baseline" @click="shareURL(data.item, true)">
                    <p class="m-0">View Full Preview </p>
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                </b-button>
            </b-dropdown>
        </template>

        <template #cell(name)="data">
            <a class="pr-0 pl-0" :href="buildAdURL(cdata.buildURL,data.item,data.item.type)">
                {{ data.value }}
            </a>
	    </template>

        <template #cell(selected)="data">
          <b-form-checkbox :checked="data.rowSelected" :value="true" @change="onRowChanged(data.index, !data.rowSelected)" class="primary" />
        </template>

        <template #cell(status)="data">
          <AdListStatus
          :ad="data.item"
          @openSpreadsheetModal="openGoogleAdsSpreadsheetModal"
          @hideModal="hideGoogleAdsSpreadsheetModal"
          />
        </template>

        <template #cell(type)="data">
            <span v-if="data.item.type == 'video'">Video</span>
            <span v-else>Display</span>
        </template>

        <template #cell(platforms)="data">
            <div v-if="getPlatformTypes(data.item).length" class="d-flex">
                <img class="platform-icon" :class="{'mr-3': index < getPlatformTypes(data.item).length + 1} " v-for="(type, index) in getPlatformTypes(data.item)" :src="staticUrl + 'img/' + type + '-logo.png'" />
            </div>
            <div v-else>
                <p class="light-grey-v-color">Connect</p>
            </div>
        </template>

        <template #cell(created_at)="data">
          <p>{{ convertDatetime(data.item.created_at) }}</p>
        </template>

        <template #cell(actions)="data">
            <b-dropdown ref="actionDropdown" right no-caret dropleft class="btn btn-outline-secondary p-0 border-0" toggle-class="btn-outline-secondary p-1 px-2 border-0">
                <template #button-content>
                    <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                </template>

                <!-- <b-dropdown-item
                v-b-tooltip.hover="{ placement: 'left' }"
                :title="isAdRenderError(data.item.progress) ? 'Tags are currently unavailable. Please allow time for the advertisement to be fully rendered or try again later.' : null"
                :disabled="isAdRenderError(data.item.progress)"
                :href="buildGetTagsUrl(cdata.buildURL,data.item)">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Get Tags</span>
                        <font-awesome-icon :icon="['fas', 'tag']" />
                    </div>
                </b-dropdown-item> -->

                <b-dropdown-item class="d-flex justify-content-between align-items-center" @click="showConnectionModal(data.item, 'connect')">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Connections</span>
                        <font-awesome-icon :icon="['fas', 'toggle-on']" />
                    </div>
                </b-dropdown-item>

                <b-dropdown-item  @click="showConnectionModal(data.item, 'disconnect')">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Disconnect</span>
                        <font-awesome-icon :icon="['fas', 'toggle-off']" />
                    </div>
                </b-dropdown-item>

                <b-dropdown-item v-b-modal.share-modal @click="shareURL(data.item)">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Share Ad</span>
                        <font-awesome-icon :icon="['fas', 'share']" />
                    </div>
                </b-dropdown-item>

                <b-dropdown-item v-if="data.item.type == 'video'" @click="onDownloadVideo(cdata.buildURL,data.item)" style="cursor: pointer;">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Download Videos</span>
                        <font-awesome-icon :icon="['fas', 'download']" />
                    </div>
                </b-dropdown-item>

                <b-dropdown-item @click="duplicateAd(data.item)">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Duplicate</span>
                        <font-awesome-icon :icon="['fas', 'copy']" />
                    </div>
                </b-dropdown-item>

                <b-dropdown-item @click="onClickDeleteAd(data.item,$event)" id="delete-btn" style="cursor: pointer;">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="pr-2">Delete</span>
                        <font-awesome-icon :icon="['fas', 'trash']" />
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </template>
        </b-table>
        <b-modal id="share-modal" title="Share Ad" hide-footer>
            <p class="d-flex justify-content-center">Anyone with this link can see a preview of {{ ad_name_modal }} for the next 30 days.</p>
            <div class="modal-body">
                <div class="share-field d-flex align-items-center justify-content-between">
                    <font-awesome-icon id="shareSpan" class="text-center" :icon="['fas', 'link']" />
                    <input id="shareInput" type="text" :value="share_url">
                    <button id="shareButton" class="btn btn-primary" @click="copyShareURL()">Copy</button>
                </div>
            </div>
        </b-modal>
        <b-modal
        id="delete-confirmation-modal"
        v-model="isShowDeleteConfirmationModal"
        title="Delete Ads?"
        ok-title="Delete Ads"
        @ok="onConfirmDelete"
        @cancel="onCancelDelete"
        @hide="resetConfirmDeleteModal()">
            <div class="modal-body">
                <StatusAlert :isSuccess="isSuccessDelete" :isShowAlert="isShowDeleteAlert" :errorMessage="deletionErrorMsg" />
                <p class="spruce-color">
                    <span class="font-weight-bold">Ads: </span>{{ getSelectedAdsName() }}
                </p>
                <p>You are about to delete ads, which will also disconnect them from any connected integrations. Are you sure you want to proceed?</p>
            </div>
        </b-modal>
        <ConnectionModal 
            v-bind="{...connectionModalProps}" 
            @hideModal="hideConnectionModal" 
            @openGoogleAdsSpreadsheetModal="openGoogleAdsSpreadsheetModal"/>
        <SpreadsheetModal v-bind="{...googleAdsSpreadsheetModalProps}" :currentStep="spreadsheetModalCurrentStep" @hideModal="hideGoogleAdsSpreadsheetModal" />
      </template>
    </BaseList>
    `
}

export {AdsTable};
