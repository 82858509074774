import { debounce } from 'lodash';

const FilterableSelectDropdown = {
    name: 'FilterableSelectDropdown',
    data: function () {
        return {
            searchQuery: "",
            selected: null,
            scrollDebounce: true,  // Prevent `onListScroll` to have multiple detection
        }
    },
    props: {
        data: Array,
        placeholders: Object,
        isLoading: Boolean
    },
    methods: {
        onSelectItem(item) {
            this.selected = item
            this.$emit('onSelectItem', item.value)
        },
        onListScroll({ target: { scrollTop, scrollHeight, offsetHeight } }) {
            if ((Math.ceil(scrollTop + offsetHeight) >= scrollHeight - 20) && this.scrollDebounce) {
                this.scrollDebounce = false;
                this.$emit('showMoreItems')

                // Handle set list detection of scroll to bottom
                let _this = this;
                setTimeout(function () {
                    if (!_this.scrollDebounce) {
                        _this.scrollDebounce = true
                    }
                }, 1000); // To prevent multiple call
            }
        },
    },
    watch: {
        searchQuery: debounce(function (newVal, oldVal) {
            this.$emit('onSearchQuery', newVal)
        }, 1000),
    },
    template:
    `
    <b-dropdown name="filterable-dropdown" dropright block text="Left align" ref="dropdown" variant="outline" class="d-block filterable-dropdown" toggle-class="border-0 w-100 p-0 text-dark text-left" menu-class="pt-0 shadow" no-caret>
        <template #button-content>
            <div class="dropdown-toggle border rounded p-2 w-100 d-flex align-items-center justify-content-between">
                <p class="selected-option mb-0 d-inline-block align-middle">{{ selected?.text || placeholders.firstOption }}</p>
            </div>
        </template>
        <div class="filterable-dropdown-content py-3 position-relative" aria-labelledby="dropdownMenu" @click.stop>
            <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': !isLoading}">
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <b-input-group class="search-input-group border ml-3 mb-3 rounded">
                <b-form-input v-model="searchQuery" class="search-input border-0" size="md" :placeholder="placeholders.search" ref="searchInputField"></b-form-input>
                <b-input-group-append>
                    <span class="input-group-text border-0 bg-transparent"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></span>
                </b-input-group-append>
            </b-input-group>
            <div v-if="!data.length" class="no-search-result d-flex justify-content-center align-items-center">
                <p v-if="!isLoading" class="m-0">{{ placeholders.noResult }}</p>
            </div>
            <div v-else class="select-item-list" @scroll="onListScroll">
                <div class="item" v-for="item in data" :key="item.value">
                    <b-dropdown-item :id="'item-name-' + item.value" @click="onSelectItem(item)">{{ item.text }}</b-dropdown-item>
                    <div class="fade-for-text"></div>
                    <b-tooltip class="item-tooltip" boundary="viewport" :target="'item-name-' + item.value" :title="item.text" triggers="hover" placement="left" />
                </div>
            </div>
        </div>
    </b-dropdown>
    `
}

export { FilterableSelectDropdown };
