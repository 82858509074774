// Ads <-> platforms connection modal
import { CheckboxGroup } from "../../common/components/CheckboxGroup.js"
import { CM360 } from "./ConnectionModalTemplates/CM360.js"
import { Meta } from "./ConnectionModalTemplates/Meta.js"
import { MerchantCenter } from "./ConnectionModalTemplates/MerchantCenter.js"
import { GoogleAds } from "./ConnectionModalTemplates/GoogleAds/GoogleAds.js"
import { StatusAlert } from "../../common/components/StatusAlert.js"
import { IntegrationsService } from "../../common/api.service.js"
import { useIntegrationStore } from "../../store/IntegrationStore.js"
import { mapState, mapActions } from 'pinia';

const ConnectionModal = {
    name: "ConnectionModal",
    data: function () {
        return {
            isLoading: false,
            // Error control
            isSuccess: false,
            isShowAlert: false,
            selectedCampaigns: [], // For CM360 and Google ads
            isConnectToAdvertiser: false,
            // State control
            selectedPlatform: null,
            platformTemplateByType: {
                google_ads: GoogleAds,
                gcm: CM360,
                meta: Meta,
                merchant_center: MerchantCenter
            },
            // Actual platform objects by group for display
            groupedPlatforms: {
                ad: [],
                catalog: []
            },
            integrationsService: null,
        }
    },
    props: {
        isShow: Boolean,
        websiteName: String,
        ads: [Object, Array],  // Array of objects if we need to connect more than one ads to the platform
        platform: [Object, String], // Can be empty string if we are opening modal to show multiple platforms
        type: String, // connect / disconnect
        platforms: Array,
        staticUrl: String
    },
    methods: {
        ...mapActions(useIntegrationStore, [
            'updateDefaultSiteSelections',
            'resetConnectionDetails'
        ]),
        closeModal(bvModalEvent, isRefresh = true) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            this.$emit('hideModal', isRefresh)
            // Reset selectedPlatform
            this.selectedPlatform = null
            // Reset statuses
            this.isSuccess = false
            this.isShowAlert = false
            this.isLoading = false
            this.resetConnectionDetails()
        },
        getAdsName() {
            if(!this.ads) return
            if(this.ads?.name) return this.ads.name
            // If ads is an array
            return this.ads.map(obj => obj.name).join(', ');
        },
        getAdsId() {
            if(!this.ads) return
            if(this.ads?.id) return this.ads.id
            return this.ads.map(obj => obj.id)
        },
        updateIsConnectToAdvertiser(newValue){
            this.isConnectToAdvertiser = newValue
        },
        bulkConnectAds(params, bvModalEvent) {
            try {
                this.integrationsService.bulkConnectAds(
                    this.selectedPlatform.type,
                    {
                        ...params
                    }
                ).then(response => {
                    this.updatePlatformDataWithSite(bvModalEvent)
                }).catch((error) => {
                    this.handleErrorCallback('bulkConnectAds', error)
                })

            } catch (error) {
                this.handleErrorCallback('bulkConnectAds', error)
            }
        },
        bulkDisconnectAds(params, bvModalEvent) {
            try {
                this.integrationsService.bulkDisconnectAds(
                    this.selectedPlatform.type,
                    {
                        ...params
                    }
                ).then(response => {
                    this.closeModal(bvModalEvent);
                    this.isLoading = false
                }).catch((error) => {
                    this.handleErrorCallback('bulkDisconnectAds', error)
                })
            } catch (error) {
                this.isSuccess = false
                this.isShowAlert = true
                this.isLoading = false
                this.handleErrorCallback('bulkDisconnectAds', error)
            }
        },
        updateAdConnection(bvModalEvent) {
            // Single ads
            const data = this.isConnect ? this.getConnectionDetailsData : []
            try {
                this.integrationsService.adConnection(
                    this.selectedPlatform.type,
                    this.ads.id,
                    data
                ).then(response => {
                    this.updatePlatformDataWithSite(bvModalEvent)
                }).catch((error) => {
                    this.handleErrorCallback('updateAdConnection', error)
                })
            } catch (error) {
                this.handleErrorCallback('updateAdConnection', error)
            }
        },
        // Store user's last selected site in platform data
        updatePlatformDataWithSite(bvModalEvent) {
            if (this.selectedPlatform.type !== this.platformNameMap.GCM) {
                if(this.selectedPlatform.type === this.platformNameMap.GOOGLE_ADS) {
                    // Prevent page refresh when updating Google Ads connection
                    this.closeModal(bvModalEvent, false);
                }else{
                    this.closeModal(bvModalEvent);
                }
                this.isLoading = false
                return
            }
            const connectionDetails = this.getConnectionDetailsData
            const lastConnectionDetail = connectionDetails[connectionDetails.length - 1]
            if (!connectionDetails || !lastConnectionDetail || !lastConnectionDetail.site_ids || lastConnectionDetail.site_ids.length === 0) {
                this.closeModal(bvModalEvent);
                this.isLoading = false
                return;
            }
            const lastSelectedSiteId = connectionDetails[connectionDetails.length - 1].site_ids;

            this.integrationsService.update(this.selectedPlatform.type, {
                settings: {
                    default_site_ids: lastSelectedSiteId
                }
            }).then(response => {
                this.closeModal(bvModalEvent);
                this.isLoading = false
            }
            ).catch(err => {
                this.$emit("showErrorAlert", err.response.data?.message)
            });
        },
        handleErrorCallback(functionName, error){
            this.isSuccess = false
            this.isShowAlert = true
            this.isLoading = false
            console.log(`${functionName} error:`, error)
        },
        OnUpdateConnection(bvModalEvent){
            this.isLoading = true
            this.isShowAlert = false
            if(!this.getIsAllConnectionDetailsValid) return

            const params = {
                ...this.getConnectionDetailsData
            }

            if(this.selectedPlatform.type === this.platformNameMap.META || this.selectedPlatform.type === this.platformNameMap.MC){
                if(this.isAdsArray){
                    params.ad_ids = this.getAdsId()

                    if(this.getIsConnect) {
                        this.bulkConnectAds(params, bvModalEvent)
                    }else{
                        this.bulkDisconnectAds(params, bvModalEvent)
                    }
                }else{
                    this.updateAdConnection(bvModalEvent);
                }
                return
            }

            if(this.isAdsArray){
                params.ad_ids = this.getAdsId()
                this.bulkConnectAds(params, bvModalEvent)
            }else{
                this.updateAdConnection(bvModalEvent);
            }
            if (this.selectedPlatform.type == this.platformNameMap.GOOGLE_ADS) {
                this.$emit("openGoogleAdsSpreadsheetModal", "generateTags")
            };
        },
        OnDisconnect(bvModalEvent){
            this.isLoading = true;
            const params = {
                ad_ids : this.getAdsId()
           };

           if(this.isAdsArray){
                this.bulkDisconnectAds(params, bvModalEvent)
            }else{
                this.updateAdConnection(bvModalEvent);
            }
        },
        onSelectPlatform(platform){
            if(!this.isPlatformEnabled(platform)) {
                // TODO: Remove this after Video Ads are fully functional and can be connected to Google Ads.
                if (platform.type === this.platformNameMap.GOOGLE_ADS && !this.isAdsArray && this.ads?.type === "video") {
                    return
                }
                // Open new tab to integration tab with specific platform integration modal pops up
                const url = `${window.location.origin}/${this.websiteName}/settings#/integrations/${platform.type}`
                window.open(url, '_blank');
            }else{
                this.selectedPlatform = platform
            }
        },
        updateSelectPlatformWithPlatform() {
            if (typeof this.platform === 'object' && this.platform !== null)
                this.selectedPlatform = this.platform
        },
        categorizePlatformsByType() {
            this.platforms.forEach(platform => {
                const platformType = platform.type.toLowerCase();

                // Check if the platformType is in the 'ads' array
                if (this.getPlatformCategoryGroups.ads.includes(platformType)) {
                    this.groupedPlatforms.ad.push(platform);
                }
                // Check if the platformType is in the 'catalog' array
                else if (this.getPlatformCategoryGroups.catalog.includes(platformType)) {
                    this.groupedPlatforms.catalog.push(platform);
                }
            });
        },
        isPlatformEnabled(platform) {
            // TODO: Remove this after Video Ads are fully functional and can be connected to Google Ads.
            if (platform.type === this.platformNameMap.GOOGLE_ADS && !this.isAdsArray && this.ads?.type === "video") {
                return false
            }
            return platform.status === this.platformStatusMap.ENABLED
        },
        updateDefaultSiteInStore() {
            const gcmPlatformData = this.platforms.find(platform => platform.type === this.platformNameMap.GCM); // Find the platform object with type 'gcm'
            if(gcmPlatformData?.default_site_ids?.length) {
                // Check if the store is updated before
                if(JSON.stringify(this.getDefaultSiteSelections) !== JSON.stringify(gcmPlatformData.default_site_ids)) {
                    this.updateDefaultSiteSelections(gcmPlatformData.default_site_ids)
                }
            }
        }
    },
    components: {
        CheckboxGroup,
        StatusAlert,
        CM360
    },
    computed: {
        ...mapState(useIntegrationStore, [
            'platformStatusMap',
            'getDefaultSiteSelections',
            'platformNameMap',
            'getPlatformCategoryGroups',
            'getConnectionDetailsData',
            'getIsAllConnectionDetailsValid',
            'getIsConnect'
        ]),
        isConnect() {
            return this.type === 'connect'
        },
        isShowAllPlatforms() {
            return !this.platform?.length && !this.selectedPlatform
        },
        isAdsArray() {
            return Array.isArray(this.ads)
        },
        platformConnectionTemplateProps() {
            return {
                ads: this.ads,
                platform: this.selectedPlatform,
                websiteName: this.websiteName,
                integrationsService: this.integrationsService
            }
        },
    },
    watch: {
        platform() { // Check if platform as props is passed in
            this.updateSelectPlatformWithPlatform()
        },
        platforms: {
            deep: true,
            handler() {
                this.categorizePlatformsByType();
            }
        }
    },
    updated () {
        // Need this to solve open multiple times to edit connection details
        this.updateSelectPlatformWithPlatform()
    },
    mounted () {
        this.integrationsService = new IntegrationsService(this.websiteName)
        this.categorizePlatformsByType();
        this.updateDefaultSiteInStore()
    },
    template:
    `
    <b-modal
    v-model="isShow"
    id="connection-modal"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    centered
    @cancel="(e) => closeModal(e, false)"
    @close="(e) => closeModal(e, false)">
        <template #modal-title>
            <span v-if="isConnect && isShowAllPlatforms">Connections</span>
            <span v-else-if="isConnect">
                <img class="platform-icon mr-2" :src="staticUrl + 'img/' + selectedPlatform?.type + '-logo.png'" />
                Manage {{ selectedPlatform?.name }} Ad Connections
            </span>
            <div v-else>
                <span v-if="isShowAllPlatforms">Disconnect</span>
                <span v-else>Disconnect {{ selectedPlatform?.name }}</span>
            </div>

        </template>

        <!-- modal-body Start -->
        <StatusAlert :isSuccess="isSuccess" :isShowAlert="isShowAlert" />

        <div class="loading-overlay position-absolute d-flex justify-content-center align-items-center bg-white opacity-50" :class="{'hide': !isLoading}">
            <b-spinner label="Loading..."></b-spinner>
        </div>

        <div class="modal-body">
            <div v-if="isShowAllPlatforms">
                <p class="spruce-color">
                    <span class="font-weight-bold">Manage your ad(s): </span>{{ getAdsName() }}
                </p>
                <p v-if="isConnect">Choose your integration to connect or manage:</p>
                <p v-else>Choose your integration to disconnect from:</p>
                <div class="platform-button-container d-flex justify-content-between flex-column">
                    <div class="mb-3">
                        <p class="disable-color">Ad Integrations</p>
                        <div class="row no-gutters">
                            <div v-for="(platform, index) in groupedPlatforms.ad" :class="index % 2 === 0 ? 'pr-2' : 'pl-2'" class="platform-button col-6">
                                <b-button :variant="isPlatformEnabled(platform) ? 'outline' : 'disabled'" class="rounded-xl border py-2 px-3 w-100 d-flex align-items-center" @click="onSelectPlatform(platform)" :key="platform.type">
                                    <img class="platform-icon mr-3" :src="staticUrl + 'img/' + platform.type + '-logo.png'" />
                                    <span class="font-weight-600">{{ platform.name }}</span>
                                </b-button>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <p class="disable-color m-0">Catalog Integrations</p>
                        <p class="light-grey-v-color font-size-sm">Catalog integrations will send item details to linked platforms.</p>
                        <div class="row no-gutters">
                            <div v-for="(platform, index) in groupedPlatforms.catalog" :class="index % 2 === 0 ? 'pr-2' : 'pl-2'" class="platform-button col-6">
                                <b-button :variant="isPlatformEnabled(platform) ? 'outline' : 'disabled'" class="rounded-xl border py-2 px-3 w-100 d-flex align-items-center" @click="onSelectPlatform(platform)" :key="platform.type">
                                    <img class="platform-icon mr-3" :src="staticUrl + 'img/' + platform.type + '-logo.png'" />
                                    <span class="font-weight-600">{{ platform.name }}</span>
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <p class="disable-color m-0">Inactive Integrations</p>
                        <div class="d-flex justify-content-between">
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div v-if="isConnect">
                    <div class="spruce-color mb-4">
                        <span class="font-weight-bold">Manage your ad(s): </span>
                        <span>{{ getAdsName() }}</span>
                    </div>
                    <component
                    :is="platformTemplateByType[selectedPlatform.type]"
                    v-bind={...platformConnectionTemplateProps}
                    @updateIsConnectToAdvertiser="updateIsConnectToAdvertiser"
                    />
                </div>


                <div v-else>
                    <p class="spruce-color">
                        <span class="font-weight-bold">Ads: </span>{{ getAdsName() }}
                    </p>
                    <p>
                        Are you sure? Disconnecting will remove these ads from {{ selectedPlatform?.name }}.
                    </p>
                </div>
            </div>
        </div>
        <!-- modal-body End -->

        <template #modal-footer>
            <b-button class="btn-spruce-outline m-1" @click="(e) => closeModal(e, false)" :disabled="isLoading">
                Cancel
            </b-button>

            <b-button
            :disabled="isLoading || !getIsAllConnectionDetailsValid || isShowAllPlatforms"
            v-if="isConnect"
            class="btn-spruce m-1"
            @click="OnUpdateConnection">
                Update Connection
            </b-button>
            <b-button v-else class="btn-spruce m-1" :disabled="isLoading || isShowAllPlatforms" @click="OnDisconnect">
                Disconnect
            </b-button>
        </template>
    </b-modal>
    `
}

export { ConnectionModal };
